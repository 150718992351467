'use client'
import React from 'react'
import styles from './Title.module.scss'
import classNames from 'classnames/bind'
import Markdown from 'markdown-to-jsx'
import useIntersectionObserver from '@/components/Helpers/UseIntersectionObserver/UseIntersectionObserver'

import { useRef, createElement } from 'react'

const classes = classNames.bind(styles)

const Title = ({
    title,
    subtitle,
    description,
    alignment = 'center',
    type,
    className,
    index = 1,
    gradient = false,
    smallGradient = false,
    variant,
    standalone,
}) => {
    const sectionRef = useRef(null)

    const subtitleRef = useRef(null)
    const titleRef = useRef(null)
    const descriptionRef = useRef(null)

    useIntersectionObserver({
        refs: [sectionRef],
        callback: ({ isIntersecting }) => {
            setTimeout(() => {
                if (isIntersecting) {
                    subtitleRef.current?.classList.add(classes('enter'))
                    titleRef.current?.classList.add(classes('enter'))
                    descriptionRef.current?.classList.add(classes('enter'))
                }
            }, 100)
        },
        options: {
            threshold: [0.2],
        },
    })

    return (
        <div style={{ position: 'relative', width: '100%' }}>
            <div
                className={classes(
                    'wrap',
                    alignment,
                    { 'first-element': index === 0, 'white-text': gradient && index === 0, standalone },
                    type,
                    className
                )}
                ref={sectionRef}
            >
                {subtitle && (
                    <span className={classes('subtitle', alignment)} ref={subtitleRef}>
                        {subtitle}
                    </span>
                )}
                {createElement(variant || 'h2', { className: classes('title', alignment), ref: titleRef }, title)}
                <div className={classes('description', alignment)} ref={descriptionRef}>
                    <Markdown children={description || ''} />
                </div>
            </div>
            {gradient && index == 0 && (
                <div id="gradient-end" className={classes('gradient-end', { small: smallGradient })} />
            )}
        </div>
    )
}

export default Title
